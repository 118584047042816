/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import home from './home';
import userManagement from './userManagement';
import informations from './informations';
import levels from '@/navigation/vertical/levels';
import NewLevel from '@/navigation/vertical/new-level';
import others from '@/navigation/vertical/others';
import subscribers from '@/navigation/vertical/subscribers';
import questionnaire from '@/navigation/vertical/questionnaire';
import paynet from '@/navigation/vertical/paynet';
import coin from '@/navigation/vertical/coin';
import ads from '@/navigation/vertical/ads';
import tournament from '@/navigation/vertical/tournament';
import newChapter from '@/navigation/vertical/new-chapter';
import kubeServers from '@/navigation/vertical/kube-servers';
import administration from '@/navigation/vertical/administration';
import scenario from '@/navigation/vertical/scenario';
import notificationV2 from '@/navigation/vertical/notificationV2';
import segmentation from '@/navigation/vertical/segmentation';
import olds from '@/navigation/vertical/olds';
import stock from '@/navigation/vertical/stock';
import roadmap from '@/navigation/vertical/roadmap';
import ussdManagement from '@/navigation/vertical/ussd-management';
import dealers from '@/navigation/vertical/dealers';

// Array of sections
export default [
    ...home,
    ...informations,
    ...dealers,
    ...ussdManagement,
    ...userManagement,
    ...levels,
    ...NewLevel,
    ...subscribers,
    ...paynet,
    ...coin,
    ...questionnaire,
    ...newChapter,
    ...roadmap,
    ...scenario,
    ...stock,
    ...notificationV2,
    ...segmentation,
    ...kubeServers,
    ...administration,
    ...ads,
    ...others,
    ...olds,
    ...tournament
];
